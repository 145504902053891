import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Header from './header';
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-number-input/input'

function FetchRequest(body, response) {
  fetch("/serviceQuestionnaire.php", {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((Response) => Response.json()).then(response);
}

const recaptchaRef = React.createRef();

function RadioLine(props) {
  return (<div className="pure-control-group">
        {props.title !== undefined ? <label>{props.title}:</label> : null}
        <span className="button-radio">
          {props.options.map((r, i) => <React.Fragment key={i}><input id={props.name+"_"+r.value} name={props.name} type="radio" checked={props.value === r.value} onChange={() => props.onChange(r.value)} disabled={props.disabled} /><label htmlFor={props.name+"_"+r.value} style={{width: "auto", display: "inline-block"}}>{r.label}</label></React.Fragment>)}
        </span>
      </div>);
}

class Identification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      sending: false,
      fn: "",
      ln: "",
      sex: null,
      ddnYear: "nc",
      ddnMonth: "nc",
      ddnDay: "nc",
      cpText: "",
      telText: "",
      clinic: null,
      clinicList: [],
      code: "",
      remainingAttempts: 0,
      captcha: null
    }
  }
  
  componentDidMount() {
    FetchRequest({action: 'get_clinics'}, (resp) => {
      this.setState({clinicList: resp.clinics, clinic: resp.clinics.length === 1 ? resp.clinics[0].value : null});
    });
  }
  
  onSubmit() {
    var data = {};
    switch (this.props.mode) {
      case 'code':
        data = {action: 'post_login', code: this.state.code, captcha: this.state.captcha};
        break;
      case 'guest':
        data = {action: 'post_register', ddn: this.state.ddnYear+"-"+this.state.ddnMonth+"-"+this.state.ddnDay, cp: this.state.cpText, tel: this.state.telText, fn: this.state.fn, ln: this.state.ln, sex: this.state.sex, clinic: this.state.clinic, captcha: this.state.captcha};
        break;
      case 'registered':
        data = {action: 'post_login', ddn: this.state.ddnYear+"-"+this.state.ddnMonth+"-"+this.state.ddnDay, cp: this.state.cpText, tel: this.state.telText, captcha: this.state.captcha};
        break;
      default:
        return;
    }
  
    this.setState({sending: true}, () => {
      FetchRequest(data, (resp) => {
        if (resp.success) {
          this.setState({error: false});
          this.props.onLogin(resp.code);
        } else {
          this.setState({error: true, sending: false, captcha: null, remainingAttempts: resp.remainingAttempts});
          window.grecaptcha.reset();
        }
      });
    });
  }
  
  render() {
    let years = [];
    let curYear = new Date().getFullYear();
    for (var i=curYear; i>curYear-110; i--) {
    	years.push(i);
    }
    let months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    let days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
  
    return (<React.Fragment>
    <Header />
    <div className="center"><div className="maincol"><div className="headertext"><h2>Bienvenue à la clinique!</h2></div>
    <p>Merci de prendre le temps de remplir ce formulaire avec précision afin d'associer vos réponses à votre dossier patient.</p>
    <p>En cas de problème ou si vous avez toute question sur le questionnaire, n'hésitez pas à <a href="https://parodontie.ca/nous-joindre/" target="_blank" rel="noopener noreferrer">nous contacter</a>.</p>
    {this.state.error ? <React.Fragment>
      <p style={{color: "red"}}>Aucun dossier ne correspond aux informations fournies. Veuillez réessayer.</p>
    </React.Fragment> : null}
    <h3>Accès au questionnaire</h3>
    {this.props.mode !== 'code' ? <div>{this.props.mode === 'guest' ? <Link to="/continuer">Cliquer ici pour terminer un formulaire que vous avez déjà partiellement complété</Link> : <Link to="/">Cliquez ici pour commencer un nouveau questionnaire</Link>}</div> : null}
    <form onSubmit={e => {e.preventDefault()}} className="pure-form pure-form-aligned">
    <fieldset>
    {this.props.mode !== 'code' ? <React.Fragment>
      <p>Tous les champs sont obligatoires.</p>
      {this.props.mode === 'guest' ? <React.Fragment>
        <div className="pure-control-group">
          <label htmlFor="fn">Prénom:</label><input id="fn" type="text" maxLength="30" value={this.state.fn} onChange={e => this.setState({fn: e.target.value})} disabled={this.state.sending} />
        </div>
        <div className="pure-control-group">
          <label htmlFor="ln">Nom de famille:</label><input id="ln" type="text" maxLength="30" value={this.state.ln} onChange={e => this.setState({ln: e.target.value})} disabled={this.state.sending} />
        </div>
        <RadioLine title="Genre" name="sex" value={this.state.sex} options={[{value: 'M', label: 'Masculin'}, {value: 'F', label: 'Féminin'}]} onChange={v => this.setState({sex: v})} disabled={this.state.sending} />
      </React.Fragment> : null}
      <div className="pure-control-group">
        <label htmlFor="ddnYear">Date de naissance:</label><select id="ddnYear" onChange={e => this.setState({ddnYear: e.target.value})} value={this.state.ddnYear} disabled={this.state.sending}><option value="nc">AAAA</option>{years.map(y => <option key={"yr"+y}>{y}</option>)}</select>&nbsp;<select id="ddnMonth" onChange={e => this.setState({ddnMonth: e.target.value})} value={this.state.ddnMonth} disabled={this.state.sending}><option value="nc">MM</option>{months.map(m => <option key={"mth"+m}>{m}</option>)}</select>&nbsp;<select id="ddnDay" onChange={e => this.setState({ddnDay: e.target.value})} value={this.state.ddnDay} disabled={this.state.sending}><option value="nc">JJ</option>{days.map(d => <option key={"day"+d}>{d}</option>)}</select>
      </div>
      <div className="pure-control-group">
        <label htmlFor="cp">Code postal:</label><input id="cp" type="text" placeholder="G1G 1G1" maxLength="7" pattern="[a-zA-Z]\d[a-zA-Z] ?\d[a-zA-Z]\d" value={this.state.cpText} onChange={e => this.setState({cpText: e.target.value})} disabled={this.state.sending} />
      </div>
      <div className="pure-control-group">
        <label htmlFor="tel">Téléphone:</label><PhoneInput id="tel" country="CA" international={false} placeholder="(418) 555-5555" maxLength="14" value={this.state.telText ? '+1'+this.state.telText:''} onChange={v => this.setState({telText: v && v.startsWith('+1') ? v.slice(2) : v})} disabled={this.state.sending} />
      </div>
      {this.props.mode === 'guest' && this.state.clinicList.length > 1 ?
          <RadioLine title="Clinique" name="clinic" value={this.state.clinic} options={this.state.clinicList} onChange={v => this.setState({clinic: v})} disabled={this.state.sending} />
      : null}

    </React.Fragment>
    : <div className="pure-control-group" style={{marginTop: '1em'}}>
      <label htmlFor="code">Entrer le code:</label><input id="code" type="text" placeholder="12345678" maxLength="8" pattern="\d{8}" inputMode="numeric" value={this.state.code} onChange={e => this.setState({code: e.target.value})} disabled={this.state.sending} />
    </div>}
    </fieldset>

    {this.state.remainingAttempts < 1 ?
      <div className="recaptcha">
        <ReCAPTCHA sitekey="6LdK1IMcAAAAAEyG-4Jq9XmWdhsC3BCilsnA5cdf" ref={recaptchaRef} onChange={r => this.setState({captcha: r})} />
      </div>
    : null}
    
    <div>
      <button className="pure-button pure-button-primary pure-input-rounded" onClick={() => this.onSubmit()} disabled={this.state.sending || (((this.state.ddnYear === 'nc' || this.state.ddnMonth === 'nc' || this.state.ddnDay === 'nc' || this.state.cpText.trim() === '' || this.state.telText.trim() === '') || (this.state.guest && (this.state.fn.trim() === '' || this.state.ln.trim() === '' || this.state.sex === null || this.state.clinic === null))) && this.state.code.length !== 8) || this.state.captcha === null}>Envoyer</button>
    </div>
    
    </form>
    
    </div>
    </div>
    </React.Fragment>);
  }
}

export default Identification;
