import React, { useState, useEffect, useRef } from 'react';
import QRCode from "react-qr-code";
import SignatureCanvas from 'react-signature-canvas'

function FetchRequest(body, response) {
  fetch("/serviceQuestionnaire.php", {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((Response) => Response.json()).then(response);
}

function Privacy(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [agree, setAgree] = useState(props.signOnly);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isError, setError] = useState(false);

  const interval = useRef(null);
  let canvasRef = useRef();

  const checkStatus = () => {
    FetchRequest({action: 'get_state', code: props.code}, (resp) => {
      if (!resp.valid) {
        clearInterval(interval);
        props.onSigned(true);
      } else if (!resp.privacyUnsigned) {
        clearInterval(interval);
        props.onSigned(false);
      }
    });
  }

  useEffect(() => {
    FetchRequest({action: 'get_is_mobile'}, (resp) => {
      setIsMobile(resp.is_mobile);
      setIsTablet(resp.is_tablet || (!isMobile && matchMedia("(pointer: coarse)").matches));
    });
    FetchRequest({action: 'get_privacy', code: props.code}, (resp) => {
      if (resp.status === 'OK') {
        if (!props.signOnly) {
          document.getElementById('privacyText').innerHTML = resp.policy;
        }
        setIsLoaded(true);
    }});
    if (!isMobile && !isTablet && interval.current === null) {
      interval.current = setInterval(checkStatus, 3000);
    }
  });

  const doSign = () => {
    if (isTablet || isMobile) {
      if(canvasRef.isEmpty()){
        setError(true);
      } else {
        let b64String = canvasRef.toDataURL('image/png');
        var loc = b64String.search("base64,");
        var retstring = b64String.slice(loc + 7, b64String.length);

        FetchRequest({action: 'post_privacy', code: props.code, signature: retstring}, (resp) => {
          if (resp.success) {
            props.onSigned(resp.finished);
          }
        });
      }
    } else {
      FetchRequest({action: 'post_privacy', code: props.code}, (resp) => {
        if (resp.success) {
          props.onSigned(resp.finished);
        }
      });
    }
  }

  return (
    <div className="content">
      {!props.signOnly && <React.Fragment>
        <p className="privacy">Veuillez lire et accepter la politique de confidentialité.</p>
        <div id="privacyText" className="privacy"></div>
      </React.Fragment>}
      <div className="center">
        {isMobile || isTablet ?
          <form className="pure-form" onSubmit={(e) => { e.preventDefault(); doSign(); }}>
            <p>&nbsp;</p>
            <input type="checkbox" id="agreeBox" onChange={() => setAgree(!agree)} checked={agree} /><label htmlFor="agreeBox">&nbsp;J'accepte la politique de confidentialité</label>
            <p>&nbsp;</p>
            {agree &&
              <React.Fragment>
                {!isTablet ?
                <div className="sigWarning">
                  Veuillez mettre votre téléphone en mode paysage afin de pouvoir signer
                </div>
                : null}
                <div className={isMobile && !isTablet ? "sigPad forceLandscape" : "sigPad"}>
                  <p className="drawItDesc">Veuillez signer avec votre doigt:</p>
                  <div className="clearButton">
                    <button type="button" className="pure-button" onClick={() => canvasRef.clear()} disabled={!isLoaded}>Effacer</button>
                  </div>
                  <SignatureCanvas disabled={!isLoaded} penColor="#135294" ref={(ref) => canvasRef = ref} canvasProps={{width: 713, height: 180, className: 'signatureCanvas'}} />
                </div>
                {isError ?
                <p className="errorText">
                  Une signature est nécessaire afin de continuer.
                </p>
                : null}
              </React.Fragment>
            }
            <p className={(isMobile && !isTablet) && agree ? "forceLandscape" : null}><button className="pure-button pure-button-primary pure-input-rounded" disabled={!isLoaded || !agree}>Envoyer</button></p>

          </form>
        :
          <form className="pure-form" onSubmit={(e) => { e.preventDefault(); doSign(); }}>
            <p>&nbsp;</p>
            <input type="checkbox" id="agreeBox" onChange={() => setAgree(!agree)} checked={agree} /><label htmlFor="agreeBox">&nbsp;J'accepte la politique de confidentialité</label>
            
            {agree ? <React.Fragment>
            <p>
              <br />Un appareil mobile est nécessaire afin de signer la politique de confidentialité.
              <br />Veuillez utiliser ce code QR afin d'accéder au formulaire de signature:
            </p>
            <p><QRCode value={window.location.origin+"/qrsign/"+props.code} /></p>
            
            </React.Fragment> : <p>&nbsp;</p>}

          </form>
        }
      </div>
      <br />
      <br />
    </div>
  );
}

export default Privacy;
